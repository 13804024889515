import axios from "axios";
import { ElLoading, ElMessage } from "element-plus";
import $router from "@/router/index";
import { useRoute } from "vue-router";
import { jwtDecode } from "jwt-decode";

let tokenVal: any = null; //定义token的值
var refreshToken: any = null;

const route: any = useRoute();

checkToken();
function checkToken() {
  //获取token的值
  if (!sessionStorage.getItem("token")) {
    tokenVal = "";
  } else {
    tokenVal = sessionStorage.getItem("token");
  }
  if (!sessionStorage.getItem("RefreshToken")) {
    refreshToken = "";
  } else {
    refreshToken = sessionStorage.getItem("RefreshToken");
  }
}

const errorBack = () => {
  // window.location.href = '/login'
  $router.push("/login");
};

// 创建一个 axios 实例
const service = axios.create({
  baseURL: "", // 所有的请求地址前缀部分
  timeout: 60000, // 请求超时时间毫秒
  withCredentials: true, // 异步请求携带cookie
  headers: {
    // 设置后端需要的传参类型
    "X-Requested-With": "XMLHttpRequest"
    // 'menuUri': window.location.pathname
  }
});

let loadingInstance: any = null;
type TokenCallback = (newToken: string | null) => void;

let isRefreshing = false;
let refreshSubscribers: TokenCallback[] = [];

// 添加请求拦截器
service.interceptors.request.use(
  async function (config: any) {
    if (
      config.url === "/api/Login/Login" || config.url ===
      "/api/Login/DingLogin" ||
      config.url === "/api/Login/DingAuthLogin"
    ) {
      if (tokenVal !== null && tokenVal !== "") {
        config.headers.Authorization =
          "Bearer " + sessionStorage.getItem("token");
      }
      return config;
    } else {
      
      checkToken()
      const decodedToken = jwtDecode(tokenVal).exp ?? 0;
      const currentTime = Math.floor(Date.now() / 1000);
      // console.log("token剩余过期时间：", decodedToken - currentTime);

      if (decodedToken - currentTime <= 120) {
        if (!isRefreshing) {
          isRefreshing = true;
          axios
            .post(
              `/api/Login/GetNewToken`,
              {},
              {
                headers: {
                  Authorization:
                    "Bearer " + sessionStorage.getItem("RefreshToken")
                }
              }
            )
            .then((response) => {
              if (response.data.ResultCode === "0000") {
                console.log("重新刷新token了", decodedToken - currentTime);
                sessionStorage.setItem("token", response.data.Token);
                sessionStorage.setItem(
                  "RefreshToken",
                  response.data.RefreshToken
                );
                // 重新发送所有等待的请求
                refreshSubscribers.forEach((callback) =>
                  callback(response.data.Token)
                );
                refreshSubscribers = [];
                isRefreshing = false;

                config.headers.Authorization =
                  "Bearer " + sessionStorage.getItem("token");
                return config;
              } else {
                refreshSubscribers.forEach((callback) => callback(null));
                refreshSubscribers = [];
                isRefreshing = false;

                ElMessage.error({
                  center: true,
                  message: "无效/过期的会话，请重新登录",
                  duration: 500,
                  offset: 350,
                  onClose: errorBack
                });
              }
            });
        }
        // 添加回调函数到队列中
        return new Promise((resolve, reject) => {
          refreshSubscribers.push((newToken: string | null) => {
            if (newToken) {
              config.headers.Authorization = `Bearer ${newToken}`;
              resolve(config);
            } else {
              reject("Token refresh failed");
            }
          });
        });
      } else {
        // 如果 Token 没有过期，直接添加到请求头
        config.headers.Authorization =
          "Bearer " + sessionStorage.getItem("token");
        return config;
      }
    }
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    if (loadingInstance !== null) {
      loadingInstance.close();
    }
    // 2xx 范围内的状态码都会触发该函数。
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data;
    const code = dataAxios.reset;
    checkToken();
    return dataAxios;
  },
  async function (error) {
    if (loadingInstance !== null) {
      loadingInstance.close();
    }
    //超时处理
    if (error.config) {
      if (error.response.status != 401) {
        console.log(error);
        ElMessage.error({
          center: true,
          message: "连接超时",
          offset: 350,
          onClose() {
            return Promise.reject(error);
          }
        });
      }
    }
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    if (error.response.status == 400) {
      ElMessage.error({
        center: true,
        message: error.response.data.message,
        offset: 350
      });
    } else if (error.response.status == 401) {
      // 问题2
      // debugger
      var strings = tokenVal.split("."); //通过split()方法将token转为字符串数组
      var userinfo = JSON.parse(
        decodeURIComponent(
          escape(window.atob(strings[1].replace(/-/g, "+").replace(/_/g, "/")))
        )
      );
      ElMessage.error({
        center: true,
        message: "无效/过期的会话，请重新登录",
        duration: 500,
        offset: 350,
        onClose: errorBack
      });
      // }
    } else if (error.response.status == 404) {
      ElMessage.error({
        center: true,
        message: "网络连接失败，请检查网络或联系管理员！",
        offset: 350
      });
    } else if (error.response.status == 405) {
      ElMessage.error({
        center: true,
        message: "网络连接失败，请检查网络或联系管理员！",
        offset: 350
      });
    } else if (error.response.status == 500) {
      ElMessage.error({
        center: true,
        message: "服务连接失败，请联系管理员！",
        offset: 350
      });
    }
    console.log(error);
    return Promise.reject(error);
  }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
const $get = (url: string, params: any) => {
  return new Promise((resolve, reject) => {
    service
      .get(url, {
        params: params
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {});
  });
};

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
const $post = (url: string, params: any) => {
  return new Promise((resolve, reject) => {
    service
      .post(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};

/**
 * put
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
const $put = (url: string, params: any) => {
  return new Promise((resolve, reject) => {
    service
      .put(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};

/**
 * delete
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
const $delete = (url: string, params: any) => {
  return new Promise((resolve, reject) => {
    service
      .delete(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};

export default {
  install: (app: any) => {
    app.config.globalProperties["$get"] = $get;
    app.config.globalProperties["$post"] = $post;
    app.config.globalProperties["$put"] = $put;
    app.config.globalProperties["$delete"] = $delete;
  }
};
