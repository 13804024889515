import { createStore } from "vuex";
export default createStore({
  state:{
    breadcrumb:[],
    style:"ancient",//风格
    color:'red',//颜色
    theme:'solidColor',//渐变色
    closeTime:0,
    OperatorId:localStorage.getItem('CJG_OperatorId')?localStorage.getItem('CJG_OperatorId'):sessionStorage.getItem('CJG_OperatorId')
  },
  mutations:{
    changeOperatorId:function (state,value){
      state.OperatorId = value
    },
    changeBreadcrumb:function (state,value){
      state.breadcrumb = value
    },
    changeColor:function(state,value){
      state.color = value
    },
    changeTheme:function(state,value){
      state.theme = value
    },
    changeStyle:function(state,value){
      state.style = value
    },
    changeCloseTime:function(state,value){
      state.closeTime = value
    }
  },
  getters:{
    getClassName:(state)=>{
      if(state.theme === "solidColor"){
        // 纯色 blue-bg
        return `${state.color}-bg`
      }else {
        //渐变 red-gradient-bg
        return `${state.color}-gradient-bg`
      }
    }
  },
});