import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store";
import http from "./request/http"  //封装axios
import ElementPlus from 'element-plus' //全局引用element Plus
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import * as ElIconModules from '@element-plus/icons';//导入所有element icon图标
import "vue3-video-play/dist/style.css";
// @ts-ignore
import  vue3VideoPlay from "vue3-video-play";
import { install } from '@icon-park/vue-next/es/all'
import "@icon-park/vue-next/styles/index.css"
import {dispatchEventStroage} from '@/hooks/watchLocal'
// 钉钉调试微应用工具
import { initDingH5RemoteDebug } from "dingtalk-h5-remote-debug";
initDingH5RemoteDebug();
const app = createApp(App)
app.use(store).use(router).use(http).use(vue3VideoPlay).use(dispatchEventStroage).use(ElementPlus, { size: 'default', locale: zhCn,});
install(app)
// 全局注册element-plus icon图标组件
Object.keys(ElIconModules).forEach((key) => {
    app.component(key, (ElIconModules as any)[key]);
});

app.mount("#app")