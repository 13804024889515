import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Index",
    meta: { title: "首页" },
    redirect: { path: "/manage" },
    component: () => import("@/views/Indexs.vue"),
    children: [
      {
        path: "/",
        name: "manageIndex",
        meta: { title: "首页" },
        redirect: { path: "/WEB" },
        component: () => import("@/views/Index.vue"),
        children: [
          // {
          //     path: '/Login',
          //     name: 'Login',
          //     meta: { title: '登录' },
          //     component: () => import('@/views/Login.vue')
          // },
          {
            path: "/organization",
            component: () =>
              import(
                /* webpackChunkName: "group-foo" */ "@/views/system/Organization.vue"
              ),
            meta: { title: "组织管理", system: "系统设置", isAuth: true }
          },
          {
            path: "/userManagement",
            component: () =>
              import(
                /* webpackChunkName: "hhh" */ "@/views/system/UserManagement.vue"
              ),
            meta: { title: "用户管理", system: "系统设置", isAuth: true }
          },
          {
            path: "/roleManagement",
            component: () => import("@/views/system/RoleManagement.vue"),
            meta: { title: "角色管理", system: "系统设置", isAuth: true }
          },
          {
            path: "/rightsManagement",
            // component:()=>import('@/views/system/RightsManagement.vue'),//旧的权限管理页面
            component: () => import("@/views/system/PowerManage.vue"),
            meta: { title: "权限管理", system: "系统设置", isAuth: true }
          },

          {
            path: "/postManagement",
            component: () => import("@/views/system/PostManagement.vue"),
            meta: { title: "岗位管理", system: "系统设置", isAuth: true }
          },
          {
            path: "/operationsManagement",
            component: () => import("@/views/system/OperationsManagement.vue"),
            meta: { title: "操作管理", system: "系统设置", isAuth: true }
          },
          {
            path: "/menuManagement",
            component: () => import("@/views/system/MenuManagement.vue"),
            meta: { title: "菜单管理", system: "系统设置", isAuth: true }
          },
          {
            path: "/dataManagement",
            component: () => import("@/views/system/DataManagement.vue"),
            meta: { title: "数据字典", system: "系统设置", isAuth: true }
          },
          {
            path: "/appearance",
            component: () => import("@/views/system/Appearance.vue"),
            meta: { title: "外观管理", system: "系统设置", isAuth: true }
          },
          {
            path: "/enterpriseFiles",
            component: () =>
              import("@/views/content/EnterpriseFiles/EnterpriseFiles.vue"),
            meta: { title: "企业文件", system: "内容维护", isAuth: true },
            children: [
              {
                path: "/enterpriseFileClassification",
                component: () =>
                  import(
                    "@/views/content/EnterpriseFiles/file/EnterpriseFileClassification.vue"
                  ),
                meta: {
                  title: "企业文件",
                  system: "内容维护",
                  childrenName: "企业文件分类",
                  isAuth: true
                }
              },
              {
                path: "/enterpriseFileManagement",
                component: () =>
                  import(
                    "@/views/content/EnterpriseFiles/file/EnterpriseFileManagement.vue"
                  ),
                meta: {
                  title: "企业文件",
                  system: "内容维护",
                  childrenName: "企业文件管理",
                  isAuth: true
                }
              },
              {
                path: "/checkEnterpriseFile",
                component: () =>
                  import(
                    "@/views/content/EnterpriseFiles/file/CheckEnterpriseFile.vue"
                  ),
                meta: {
                  title: "企业文件",
                  system: "内容维护",
                  childrenName: "企业文件查看",
                  isAuth: true
                }
              },
              {
                path: "/enterpriseFilePermissions",
                component: () =>
                  import(
                    "@/views/content/EnterpriseFiles/file/RightsManagement.vue"
                  ),
                meta: {
                  title: "企业文件",
                  system: "内容维护",
                  childrenName: "企业文件操作配置",
                  isAuth: true
                }
              },
              {
                path: "/enterpriseFileVersion",
                component: () =>
                  import("@/views/content/EnterpriseFiles/file/Versioning.vue"),
                meta: {
                  title: "企业文件",
                  system: "内容维护",
                  childrenName: "企业文件版本管理",
                  isAuth: true
                }
              }
            ]
          },
          {
            path: "/standardSpecification",
            component: () =>
              import(
                "@/views/content/StandardSpecification/StandardSpecification.vue"
              ),
            meta: { title: "标准规范", system: "内容维护", isAuth: true },
            children: [
              {
                path: "/classification",
                component: () =>
                  import(
                    "@/views/content/StandardSpecification/file/Classification.vue"
                  ),
                meta: {
                  title: "标准规范",
                  system: "内容维护",
                  childrenName: "标准规范分类",
                  isAuth: true
                }
              },
              {
                path: "/manage",
                component: () =>
                  import(
                    "@/views/content/StandardSpecification/file/Manage.vue"
                  ),
                meta: {
                  title: "标准规范",
                  system: "内容维护",
                  childrenName: "标准规范管理",
                  isAuth: true
                }
              }
            ]
          },
          {
            path: "/standardAtlas",
            component: () =>
              import("@/views/content/StandardAtlas/StandardAtlas.vue"),
            meta: { title: "标准图集", system: "内容维护", isAuth: true },
            children: [
              {
                path: "/standardAtlasClassification",
                component: () =>
                  import(
                    "@/views/content/StandardAtlas/file/Classification.vue"
                  ),
                meta: {
                  title: "标准图集",
                  system: "内容维护",
                  childrenName: "标准图集分类",
                  isAuth: true
                }
              },
              {
                path: "/standardAtlasManage",
                component: () =>
                  import("@/views/content/StandardAtlas/file/Manage.vue"),
                meta: {
                  title: "标准图集",
                  system: "内容维护",
                  childrenName: "标准图集管理",
                  isAuth: true
                }
              }
            ]
          },
          {
            path: "/lawsRegulations",
            component: () =>
              import("@/views/content/lawsRegulations/lawsRegulations.vue"),
            meta: { title: "法律法规", system: "内容维护", isAuth: true },
            children: [
              {
                path: "/classificationRegulations",
                component: () =>
                  import(
                    "@/views/content/lawsRegulations/file/ClassificationRegulations.vue"
                  ),
                meta: {
                  title: "法律法规",
                  system: "内容维护",
                  childrenName: "法律法规分类",
                  isAuth: true
                }
              },
              {
                path: "/manageRegulations",
                component: () =>
                  import(
                    "@/views/content/lawsRegulations/file/ManageRegulations.vue"
                  ),
                meta: {
                  title: "法律法规",
                  system: "内容维护",
                  childrenName: "法律法规管理",
                  isAuth: true
                }
              }
            ]
          },
          {
            path: "/material",
            component: () => import("@/views/content/Material/Material.vue"),
            meta: { title: "材料", system: "内容维护", isAuth: true },
            children: [
              {
                path: "/materialClassification",
                component: () =>
                  import(
                    "@/views/content/Material/file/MaterialClassification.vue"
                  ),
                meta: {
                  title: "材料",
                  system: "内容维护",
                  childrenName: "材料分类",
                  isAuth: true
                }
              },
              {
                path: "/materialManage",
                component: () =>
                  import("@/views/content/Material/file/MaterialManage.vue"),
                meta: {
                  title: "材料",
                  system: "内容维护",
                  childrenName: "材料管理",
                  isAuth: true
                }
              }
            ]
          },
          {
            path: "/machinery",
            component: () => import("@/views/content/Machinery/Machinery.vue"),
            meta: { title: "机械", system: "内容维护", isAuth: true },
            children: [
              {
                path: "/machineryClassification",
                component: () =>
                  import(
                    "@/views/content/Machinery/file/MachineryClassification.vue"
                  ),
                meta: {
                  title: "机械",
                  system: "内容维护",
                  childrenName: "机械分类",
                  isAuth: true
                }
              },
              {
                path: "/machineryManage",
                component: () =>
                  import("@/views/content/Machinery/file/MachineryManage.vue"),
                meta: {
                  title: "机械",
                  system: "内容维护",
                  childrenName: "机械管理",
                  isAuth: true
                }
              }
            ]
          },
          {
            path: "/knowledgeEnhancement",
            component: () =>
              import(
                "@/views/content/KnowledgeEnhancement/KnowledgeEnhancement.vue"
              ),
            meta: { title: "知识提升", system: "内容维护", isAuth: true },
            children: [
              {
                path: "/classificationKnowledge",
                component: () =>
                  import(
                    "@/views/content/KnowledgeEnhancement/file/Classification.vue"
                  ),
                meta: {
                  title: "知识提升",
                  system: "内容维护",
                  childrenName: "知识提升分类",
                  isAuth: true
                }
              },
              {
                path: "/manageKnowledge",
                component: () =>
                  import(
                    "@/views/content/KnowledgeEnhancement/file/Manage.vue"
                  ),
                meta: {
                  title: "知识提升",
                  system: "内容维护",
                  childrenName: "知识提升管理",
                  isAuth: true
                }
              }
            ]
          },
          {
            path: "/constructionTechnology",
            component: () =>
              import(
                "@/views/content/ConstructionTechnology/ConstructionTechnology.vue"
              ),
            meta: { title: "施工工艺", system: "内容维护", isAuth: true },
            children: [
              {
                path: "/classificationConstruction",
                component: () =>
                  import(
                    "@/views/content/ConstructionTechnology/file/Classification.vue"
                  ),
                meta: {
                  title: "施工工艺",
                  system: "内容维护",
                  childrenName: "施工工艺分类",
                  isAuth: true
                }
              },
              {
                path: "/manageConstruction",
                component: () =>
                  import(
                    "@/views/content/ConstructionTechnology/file/Manage.vue"
                  ),
                meta: {
                  title: "施工工艺",
                  system: "内容维护",
                  childrenName: "施工工艺管理",
                  isAuth: true
                }
              }
            ]
          },
          {
            path: "/discuss",
            component: () => import("@/views/content/forum/Discuss.vue"),
            meta: { title: "知识问答讨论区", system: "内容维护", isAuth: true }
          },
          {
            path: "/post",
            component: () => import("@/views/content/forum/Post.vue"),
            meta: {
              title: "知识问答帖子管理",
              system: "内容维护",
              isAuth: true
            }
          },
          {
            path: "/label",
            component: () => import("@/views/content/forum/Label.vue"),
            meta: {
              title: "知识问答标签管理",
              system: "内容维护",
              isAuth: true
            }
          },
          {
            path: "/expert",
            component: () => import("@/views/content/forum/Expert.vue"),
            meta: {
              title: "知识问答专家管理",
              system: "内容维护",
              isAuth: true
            }
          },
          {
            path: "/expertKnowledgeSharing",
            component: () =>
              import("@/views/content/forum/ExpertKnowledgeSharing.vue"),
            meta: {
              title: "专家知识分享管理",
              system: "内容维护",
              isAuth: true
            }
          }
        ]
      },
      {
        path: "/web",
        name: "Index",
        redirect: { path: "/gatewayWEB/index" },
        meta: { title: "首页" },
        component: () => import("@/views/IndexWEb.vue"),
        children: [
          {
            path: "/gatewayWEB",
            redirect: { path: "/gatewayWEB/index" },
            component: () => import("@/views/WEB/Gateway.vue"),
            meta: { title: "知识门户", system: "前端", isAuth: true },
            children: [
              {
                path: "index",
                component: () => import("@/views/WEB/GateWay/GIndex.vue"),
                meta: { title: "知识门户", system: "前端", isAuth: true }
              },
              {
                path: "enterpriseFilesWEB",
                component: () =>
                  import("@/views/WEB/GateWay/EnterpriseFiles.vue"),
                meta: { title: "企业文件", system: "前端", isAuth: true }
              },
              {
                path: "contractInfo",
                component: () => import("@/views/WEB/GateWay/ContractInfo.vue"),
                meta: { title: "合同详情", system: "前端", isAuth: true }
              },
              {
                path: "specificationWEB",
                component: () =>
                  import("@/views/WEB/GateWay/Specification.vue"),
                meta: { title: "标准规范", system: "前端", isAuth: true }
              },
              {
                path: "specificationInfo",
                component: () =>
                  import("@/views/WEB/GateWay/SpecificationInfo.vue"),
                meta: { title: "标准规范详情", system: "前端", isAuth: true }
              },
              {
                path: "replaceStandard",
                component: () =>
                  import("@/views/WEB/GateWay/ReplaceStandard.vue"),
                meta: { title: "替代规范", system: "前端", isAuth: true }
              },
              {
                path: "replaceStandardAtlas",
                component: () =>
                  import("@/views/WEB/GateWay/ReplaceStandardAtlas.vue"),
                meta: { title: "替代图集", system: "前端", isAuth: true }
              },
              {
                path: "replaceStandardInfo",
                component: () =>
                  import("@/views/WEB/GateWay/ReplaceStandardInfo.vue"),
                meta: { title: "替代规范详情", system: "前端", isAuth: true }
              },
              {
                path: "standardAtlasWEB",
                component: () =>
                  import("@/views/WEB/GateWay/StandardAtlas.vue"),
                meta: { title: "标准图集", system: "前端", isAuth: true }
              },
              {
                path: "lawRegulations",
                component: () =>
                  import("@/views/WEB/GateWay/LawRegulations.vue"),
                meta: { title: "法律法规", system: "前端", isAuth: true }
              },
              {
                path: "materialWEB",
                component: () => import("@/views/WEB/GateWay/Material.vue"),
                meta: { title: "材料", system: "前端", isAuth: true }
              },
              {
                path: "materialInfo",
                component: () => import("@/views/WEB/GateWay/MaterialInfo.vue"),
                meta: { title: "材料详情/机械", system: "前端", isAuth: true }
              },
              {
                path: "machineryWEB",
                component: () => import("@/views/WEB/GateWay/Machinery.vue"),
                meta: { title: "机械", system: "前端", isAuth: true }
              },
              {
                path: "knowledgeWEB",
                component: () => import("@/views/WEB/GateWay/Knowledge.vue"),
                meta: { title: "知识分享", system: "前端", isAuth: true }
              },
              {
                path: "knowledgeInfo",
                component: () =>
                  import("@/views/WEB/GateWay/KnowledgeInfo.vue"),
                meta: { title: "课程详情", system: "前端", isAuth: true }
              },
              {
                path: "noteInfo",
                component: () => import("@/views/WEB/GateWay/NoteInfo.vue"),
                meta: { title: "笔记详情", system: "前端", isAuth: true }
              },
              {
                path: "technologyWEB",
                component: () => import("@/views/WEB/GateWay/Technology.vue"),
                meta: { title: "施工工艺", system: "前端", isAuth: true }
              },
              {
                path: "technologyInfoWEB",
                component: () =>
                  import("@/views/WEB/GateWay/TechnologyInfo.vue"),
                meta: { title: "施工工艺详情", system: "前端", isAuth: true }
              },
              {
                path: "technologyInfoWEBTWO",
                component: () =>
                  import("@/views/WEB/GateWay/TechnologyInfoTwo.vue"),
                meta: { title: "施工工艺详情二", system: "前端", isAuth: true }
              }
            ]
          },
          {
            path: "/search",
            component: () => import("@/views/WEB/Search.vue"),
            meta: { title: "搜索", system: "前端", isAuth: true }
          },
          {
            path: "/themeWEB",
            component: () => import("@/views/WEB/Theme.vue"),
            meta: { title: "主题", system: "前端", isAuth: true }
          },
          {
            path: "/discussWEB",
            component: () => import("@/views/WEB/questionAnswering/Index.vue"),
            meta: { title: "知识问答", system: "前端", isAuth: true },
            redirect: { path: "/discussWEB/indexContent" },
            children: [
              {
                path: "indexContent",
                component: () =>
                  import("@/views/WEB/questionAnswering/IndexContent.vue"),
                meta: { title: "知识问答首页", system: "前端", isAuth: true }
              },
              {
                path: "discussionAreaWEB",
                component: () =>
                  import("@/views/WEB/questionAnswering/DiscussionArea.vue"),
                meta: { title: "讨论区", system: "前端", isAuth: true }
              },
              {
                path: "postInfoWEB",
                component: () =>
                  import("@/views/WEB/questionAnswering/PostInfo.vue"),
                meta: { title: "帖子详情", system: "前端", isAuth: true }
              },
              {
                path: "knowledgeSharingWEB",
                component: () =>
                  import("@/views/WEB/questionAnswering/KnowledgeSharing.vue"),
                meta: { title: "知识分享详情", system: "前端", isAuth: true }
              }
            ]
          },
          {
            path: "/chartsWEB",
            component: () => import("@/views/WEB/questionAnswering/Charts.vue"),
            meta: { title: "专家排行", system: "前端", isAuth: true }
          }
        ]
      }
    ]
  },
  {
    path: "/PromotionalMV",
    name: "PromotionalMV",
    meta: { title: "企业宣传视频" },
    component: () => import("@/views/PromotionalMV.vue")
  },
  {
    path: "/SafeMv",
    name: "SafeMv",
    meta: { title: "安全帽视频" },
    component: () => import("@/views/SafeMv.vue")
  },
  {
    path: "/HlytMv",
    name: "HlytMv",
    meta: { title: "海陆烟台宣传视频" },
    component: () => import("@/views/HlytMv.vue")
  },
  {
    path: "/LoginOne",
    name: "LoginOne",
    meta: { title: "登录一" },
    component: () => import("@/views/LoginOne.vue")
  },
  {
    path: "/Login",
    name: "Login",
    meta: { title: "登录" },
    component: () => import("@/views/Login.vue")
  },
  {
    path: "/threeLogin",
    name: "threeLogin",
    meta: { title: "第三方登录" },
    component: () => import("@/views/threeLogin.vue")
  },
  // 苹果电脑，钉钉扫码登录
  {
    path: "/dingScanCodeLogin",
    name: "dingScanCodeLogin",
    meta: { title: "钉钉扫码登录" },
    component: () => import("@/views/dingScanCodeLogin.vue")
  },
  {
    path: "/machinery_materialInfo",
    component: () =>
      import("@/views/content/Machinery/Machinery_materialInfo.vue"),
    meta: { title: "机械/材料详情页面", system: "内容维护", isAuth: true }
  },
  {
    path: "/GFInfo",
    component: () =>
      import("@/views/content/StandardSpecification/file/GFInfo.vue"),
    meta: { title: "标准规范详情", system: "前端", isAuth: true }
  },
  {
    path: "/knowledge_materialInfo",
    component: () =>
      import("@/views/content/KnowledgeEnhancement/Knowledge_materialInfo.vue"),
    meta: { title: "知识提升详情页面", system: "内容维护", isAuth: true }
  },
  {
    path: "/constructionTechnologyInfo",
    component: () =>
      import(
        "@/views/content/ConstructionTechnology/ConstructionTechnologyInfo.vue"
      ),
    meta: { title: "施工工艺详情页面", system: "内容维护", isAuth: true }
  },
  {
    path: "/checkFile",
    component: () => import("@/views/WEB/GateWay/CheckFile.vue"),
    meta: { title: "标准规范详情", system: "前端", isAuth: true }
  },
  {
    path: "/checkFileTwo",
    component: () => import("@/views/WEB/GateWay/CheckFileTwo.vue"),
    meta: { title: "标准图集详情", system: "前端", isAuth: true }
  },
  {
    path: "/checkFileLawer",
    component: () => import("@/views/WEB/GateWay/checkFileLawer.vue"),
    meta: { title: "标准图集详情", system: "前端", isAuth: true }
  },
  {
    path: "/FileSourceFile",
    component: () =>
      import("@/views/content/EnterpriseFiles/file/FileSourceFile.vue"),
    meta: { title: "文件查看", system: "前端", isAuth: true }
  },
  {
    path: "/KnowledgeSharingInfo",
    component: () => import("@/views/content/forum/KnowledgeSharingInfo.vue"),
    meta: { title: "知识分享详情", system: "内容维护", isAuth: true }
  },
  {
    path: "/404",
    component: () => import("@/views/NotFound.vue")
  },
  {
    path: "/postInfo",
    component: () => import("@/views/content/forum/PostInfo.vue"),
    meta: { title: "后台帖子详情", system: "内容维护", isAuth: true }
  },
  {
    path: "/specificationInfoDD",
    component: () => import("@/views/WEB/GateWay/SpecificationInfoDD.vue"),
    meta: { title: "标准规范详情", system: "前端", isAuth: true }
  },
  {
    path: "/checkFileDD",
    component: () => import("@/views/WEB/GateWay/CheckFileDD.vue"),
    meta: { title: "标准规范详情", system: "前端", isAuth: true }
  },

  {
    path: "/LearnCommunityPreview",
    component: () => import("@/views/study/LearnCommunityPreview.vue"),
    meta: { title: "学习社区预览", system: "前端", isAuth: true }
  },
  {
    path: "/StudyIndex",
    name: "StudyIndex",
    component: () => import("@/views/study/StudyIndex.vue"),
    meta: { title: "海陆网院", system: "前端", isAuth: true },
    redirect: { path: "/StudyIndexPage" },
    children: [
      {
        path: "/StudyIndexPage",
        name: "StudyIndexPage",
        component: () => import("@/views/study/StudyIndexPage.vue"),
        meta: { title: "海陆网院", system: "前端", isAuth: true },
        redirect: { path: "/StudyHomePage" },
        children: [
          {
            path: "/StudyHomePage",
            name: "StudyHomePage",
            component: () => import("@/views/study/StudyHomePage.vue"),
            meta: { title: "首页", system: "前端", isAuth: true },
            redirect: { path: "/StudyHomePage/index" },
            children: [
              {
                path: "index",
                component: () => import("@/views/study/StudyHomePageIndex.vue"),
                meta: { title: "首页", system: "前端", isAuth: true }
              },
              {
                path: "RecommendedTopic",
                component: () => import("@/views/study/RecommendedTopic.vue"),
                meta: { title: "推荐专题", system: "前端", isAuth: true }
              },
              {
                path: "SearchPage",
                component: () => import("@/views/study/SearchPage.vue"),
                meta: { title: "搜索", system: "前端", isAuth: true }
              },
              {
                path: "TrainingProgram",
                component: () => import("@/views/study/TrainingProgram.vue"),
                meta: { title: "培训计划", system: "前端", isAuth: true }
              }
            ]
          },
          {
            path: "/StudyPage",
            name: "StudyPage",
            component: () => import("@/views/study/StudyPage.vue"),
            meta: { title: "学习中心", system: "前端", isAuth: true },
            redirect: { path: "/StudyPage/index" },
            children: [
              {
                path: "index",
                component: () => import("@/views/study/ZoneIndex.vue"),
                meta: { title: "学习中心", system: "前端", isAuth: true }
              },
              {
                path: "ZoneDetailMore",
                component: () => import("@/views/study/ZoneDetailMore.vue"),
                meta: { title: "更多", system: "前端", isAuth: true }
              },
              {
                path: "ZoneDetail",
                component: () => import("@/views/study/ZoneDetail.vue"),
                meta: { title: "专题详情", system: "前端", isAuth: true }
              },
              {
                path: "TrainingDynamics",
                component: () => import("@/views/study/TrainingDynamics.vue"),
                meta: { title: "培训动态", system: "前端", isAuth: true }
              },
              {
                path: "TrainingDynamicsDetail",
                component: () =>
                  import("@/views/study/TrainingDynamicsDetail.vue"),
                meta: { title: "培训动态", system: "前端", isAuth: true }
              },
              {
                path: "ClassDetail",
                component: () => import("@/views/study/ClassDetail.vue"),
                meta: { title: "班级详情", system: "前端", isAuth: true }
              },
              {
                path: "ClassLessons",
                component: () => import("@/views/study/ClassLessons.vue"),
                meta: { title: "查看全部课程", system: "前端", isAuth: true }
              },
              {
                path: "ZoneDetailInfo",
                component: () => import("@/views/study/ZoneDetailInfo.vue"),
                meta: { title: "专区课程详情", system: "前端", isAuth: true }
              },
              {
                path: "ZoneDetailInfoWare",
                component: () => import("@/views/study/ZoneDetailInfoWare.vue"),
                meta: { title: "专区课件详情", system: "前端", isAuth: true }
              },
              {
                path: "ClassCourseDetailInfo",
                component: () =>
                  import("@/views/study/ClassCourseDetailInfo.vue"),
                meta: { title: "班级课程详情", system: "前端", isAuth: true }
              },
              {
                path: "ClassWareDetailInfo",
                component: () =>
                  import("@/views/study/ClassWareDetailInfo.vue"),
                meta: { title: "班级课件详情", system: "前端", isAuth: true }
              },
              {
                path: "ClassEvaluateDetailInfo",
                component: () =>
                  import("@/views/study/ClassEvaluateDetailInfo.vue"),
                meta: { title: "班级评估详情", system: "前端", isAuth: true }
              },
              {
                path: "ClassExamDetailInfo",
                component: () =>
                  import("@/views/study/ClassExamDetailInfo.vue"),
                meta: { title: "班级考试详情", system: "前端", isAuth: true }
              },
              {
                path: "ClassExamDetailInfoEnd",
                component: () =>
                  import("@/views/study/ClassExamDetailInfoEnd.vue"),
                meta: {
                  title: "班级考试详情(已结束)",
                  system: "前端",
                  isAuth: true
                }
              },
              {
                path: "Exam",
                component: () => import("@/views/study/Exam.vue"),
                meta: { title: "考试", system: "前端", isAuth: true }
              },
              {
                path: "ExamResult",
                component: () => import("@/views/study/ExamResult.vue"),
                meta: { title: "考试结果", system: "前端", isAuth: true }
              },
              {
                path: "ExamRecord",
                component: () => import("@/views/study/ExamRecord.vue"),
                meta: { title: "考试记录", system: "前端", isAuth: true }
              },
              {
                path: "ClassPractice",
                component: () => import("@/views/study/ClassPractice.vue"),
                meta: { title: "随堂练习", system: "前端", isAuth: true }
              },

              {
                path: "ZoneDetailCate",
                component: () => import("@/views/study/ZoneDetailCate.vue"),
                meta: { title: "专区分类", system: "前端", isAuth: true }
              }
            ]
          },
          {
            path: "/CourseCenter",
            name: "CourseCenter",
            component: () => import("@/views/study/CourseCenter.vue"),
            meta: { title: "课程中心", system: "前端", isAuth: true },
            redirect: { path: "/CourseCenter/index" },
            children: [
              {
                path: "index",
                name: "CourseCenterIndex",
                component: () => import("@/views/study/CourseCenterIndex.vue"),
                meta: { title: "首页", system: "前端", isAuth: true }
              },
              {
                path: "CourseCenterTopic",
                component: () => import("@/views/study/CourseCenterTopic.vue"),
                meta: { title: "全部课程", system: "前端", isAuth: true }
              },
              {
                path: "CourseMore",
                component: () => import("@/views/study/CourseMore.vue"),
                meta: { title: "课程推荐更多", system: "前端", isAuth: true }
              },
              {
                path: "CourseDetails",
                component: () => import("@/views/study/CourseDetails.vue"),
                meta: { title: "课程详情", system: "前端", isAuth: true }
              },
              {
                path: "CourseEvaluate",
                component: () => import("@/views/study/CourseEvaluate.vue"),
                meta: { title: "课程评估", system: "前端", isAuth: true }
              },
              {
                path: "CoursePractice",
                component: () => import("@/views/study/CoursePractice.vue"),
                meta: { title: "随堂练习", system: "前端", isAuth: true }
              }
            ]
          },

          {
            path: "/TeacherPage",
            name: "TeacherPage",
            component: () => import("@/views/study/TeacherPage.vue"),
            meta: { title: "优秀师资", system: "前端", isAuth: true },
            redirect: { path: "/TeacherPage/index" },
            children: [
              {
                path: "index",
                component: () => import("@/views/study/TeacherPageIndex.vue"),
                meta: { title: "优秀师资", system: "前端", isAuth: true }
              },
              {
                path: "TeacherInfo",
                name: "TeacherInfo",
                component: () => import("@/views/study/TeacherInfo.vue"),
                meta: { title: "师资详情", system: "前端", isAuth: true }
              }
            ]
          },
          {
            path: "/LearnCommunity",
            name: "LearnCommunity",
            component: () => import("@/views/study/LearnCommunity.vue"),
            meta: { title: "学习社区", system: "前端", isAuth: true },
            redirect: { path: "/LearnCommunity/index" },
            children: [
              {
                path: "index",
                component: () =>
                  import("@/views/study/LearnCommunityIndex.vue"),
                meta: { title: "学习社区", system: "前端", isAuth: true }
              },
              {
                path: "detail",
                component: () =>
                  import("@/views/study/LearnCommunityDetail.vue"),
                meta: { title: "学习社区详情", system: "前端", isAuth: true }
              }
            ]
          },

          {
            path: "/CjgPage",
            name: "CjgPage",
            component: () => import("@/views/study/CjgPage.vue"),
            meta: { title: "藏经阁页面", system: "前端", isAuth: true }
          },
          {
            path: "/MyselfPage",
            name: "MyselfPage",
            component: () => import("@/views/study/MyselfPage.vue"),
            meta: { title: "个人中心", system: "前端", isAuth: true },
            redirect: { path: "/MyselfPage/MyUpload" },
            children: [
              {
                path: "/MyselfPage/MyAnalyticsDashboard",
                name: "MyAnalyticsDashboard",
                component: () =>
                  import("@/views/study/MyAnalyticsDashboard.vue"),
                meta: { title: "统计看板", system: "前端", isAuth: true }
              },
              {
                path: "MyUpload",
                name: "MyUpload",
                component: () => import("@/views/study/MyUpload.vue"),
                meta: { title: "我的上传", system: "前端", isAuth: true }
              },
              {
                path: "MyCollect",
                name: "MyCollect",
                component: () => import("@/views/study/MyCollect.vue"),
                meta: { title: "我的收藏", system: "前端", isAuth: true }
              },
              {
                path: "MyClass",
                name: "MyClass",
                component: () => import("@/views/study/MyClass.vue"),
                meta: { title: "我的班级", system: "前端", isAuth: true }
              },
              {
                path: "MyCourse",
                name: "MyCourse",
                component: () => import("@/views/study/MyCourse.vue"),
                meta: { title: "我的课程", system: "前端", isAuth: true }
              },
              {
                path: "MyExam",
                name: "MyExam",
                component: () => import("@/views/study/MyExam.vue"),
                meta: { title: "我的考试", system: "前端", isAuth: true }
              },
              {
                path: "MyRemark",
                name: "MyRemark",
                component: () => import("@/views/study/MyRemark.vue"),
                meta: { title: "我的评论", system: "前端", isAuth: true }
              },
              {
                path: "MyQuestion",
                name: "MyQuestion",
                component: () => import("@/views/study/MyQuestion.vue"),
                meta: { title: "我的问卷", system: "前端", isAuth: true }
              },
              {
                path: "MyWrong",
                name: "MyWrong",
                component: () => import("@/views/study/MyWrong.vue"),
                meta: { title: "我的错题本", system: "前端", isAuth: true }
              },
              {
                path: "MyShare",
                name: "MyShare",
                component: () => import("@/views/study/MyShare.vue"),
                meta: { title: "我的分享", system: "前端", isAuth: true }
              },
              {
                path: "MyExamGrading",
                name: "MyExamGrading",
                component: () => import("@/views/study/MyExamGrading.vue"),
                meta: { title: "考试批阅", system: "前端", isAuth: true }
              },
              {
                path: "MyCertificate",
                name: "MyCertificate",
                component: () => import("@/views/study/MyCertificate.vue"),
                meta: { title: "我的证书", system: "前端", isAuth: true }
              }
            ]
          },
          {
            path: "/MyselfPage/MyIntegral",
            name: "MyIntegral",
            component: () => import("@/views/study/MyIntegral.vue"),
            meta: { title: "个人-积分详情", system: "前端", isAuth: true }
          },
          {
            path: "/MyselfPage/MySelfClassDetail",
            name: "MySelfClassDetail",
            component: () => import("@/views/study/MySelfClassDetail.vue"),
            meta: { title: "个人-班级详情", system: "前端", isAuth: true }
          },
          {
            path: "/MyselfPage/MyQueInfo",
            name: "MyQueInfo",
            component: () => import("@/views/study/MyQueInfo.vue"),
            meta: { title: "个人-开始评价", system: "前端", isAuth: true }
          },
          {
            path: "/MyselfPage/MyQueClass",
            name: "MyQueClass",
            component: () => import("@/views/study/MyQueClass.vue"),
            meta: { title: "个人-班级的评价", system: "前端", isAuth: true }
          },
          {
            path: "/MyselfPage/MyWrongInfo",
            name: "MyWrongInfo",
            component: () => import("@/views/study/MyWrongInfo.vue"),
            meta: { title: "个人-我的错题", system: "前端", isAuth: true }
          },
          {
            path: "/MyselfPage/MyWrongtext",
            name: "MyWrongtext",
            component: () => import("@/views/study/MyWrongtext.vue"),
            meta: { title: "个人-错题练习", system: "前端", isAuth: true }
          },
          {
            path: "/MyselfPage/MyExamination",
            name: "MyExamination",
            component: () => import("@/views/study/MyExamination.vue"),
            meta: { title: "个人-考试", system: "前端", isAuth: true }
          },
          {
            path: "/MyselfPage/MyExaminationMid",
            name: "MyExaminationMid",
            component: () => import("@/views/study/MyExaminationMid.vue"),
            meta: { title: "个人-考试中", system: "前端", isAuth: true }
          },
          {
            path: "/MyselfPage/MyExaminationEnd",
            name: "MyExaminationEnd",
            component: () => import("@/views/study/MyExaminationEnd.vue"),
            meta: { title: "个人-考试结束页", system: "前端", isAuth: true }
          },
          {
            path: "/MyselfPage/MyExaminationEnd1",
            name: "MyExaminationEnd1",
            component: () => import("@/views/study/MyExaminationEnd1.vue"),
            meta: { title: "个人-未考试页", system: "前端", isAuth: true }
          },
          {
            path: "/MyselfPage/MyExaminationText",
            name: "MyExaminationText",
            component: () => import("@/views/study/MyExaminationText.vue"),
            meta: { title: "个人-随堂练习-班级", system: "前端", isAuth: true }
          },
          {
            path: "/MyselfPage/MyExaminationBook",
            name: "MyExaminationBook",
            component: () => import("@/views/study/MyExaminationBook.vue"),
            meta: { title: "个人-随堂练习-课程", system: "前端", isAuth: true }
          },
          {
            path: "/MyselfPage/MyCourseInfo",
            name: "MyCourseInfo",
            component: () => import("@/views/study/MyCourseInfo.vue"),
            meta: { title: "个人-课程详情", system: "前端", isAuth: true }
          },
          {
            path: "/MyselfPage/MyExaminResult",
            name: "MyExaminResult",
            component: () => import("@/views/study/MyExaminResult.vue"),
            meta: { title: "个人-考试查看结果", system: "前端", isAuth: true }
          },

          {
            path: "/MyselfPage/MyClassDetails",
            name: "MyClassDetails",
            component: () => import("@/views/study/MyClassDetails.vue"),
            meta: { title: "个人-班级-班级详情", system: "前端", isAuth: true }
          },
          {
            path: "/MyselfPage/MyCourseDetails",
            name: "MyCourseDetails",
            component: () => import("@/views/study/MyCourseDetails.vue"),
            meta: { title: "个人-课程-班级详情", system: "前端", isAuth: true }
          },
          {
            path: "/MyselfPage/MyExamApply",
            name: "MyExamApply",
            component: () => import("@/views/study/MyExamApply.vue"),
            meta: { title: "个人-考试批阅详情", system: "前端", isAuth: true }
          },
          {
            path: "/MyselfPage/MyExamApplyLook",
            name: "MyExamApplyLook",
            component: () => import("@/views/study/MyExamApplyLook.vue"),
            meta: { title: "个人-考试批阅查看", system: "前端", isAuth: true }
          }
        ]
      }
    ]
  },

  {
    /**
     * 路由重定向
     */
    path: "/:pathMatch(.*)*",
    redirect: "/404"
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  // store.commit("changeBreadcrumb",to.meta)
  let token = sessionStorage.getItem("token"); //读取token
  if (!token) {
    token = sessionStorage.getItem("token");
  }
  if (to.meta.isAuth) {
    //判断是否需要鉴权

    // 初始化知识问答讨论区的切换类型
    if (from.path == "/discussWEB/indexContent") {
      localStorage.setItem("activeType", "0");
    }

    if (to.query.corpId) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      window.scrollTo(0, 0);
      next();
    } else {
      if (token === null || token == "undefined" || token == undefined) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        window.scrollTo(0, 0);
        if (to.query.authCode == undefined) {
          next({ name: "Login" });
        } else {
          next();
        }
      } else {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        window.scrollTo(0, 0);
        next();
        // }
      }
    }
  } else {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.scrollTo(0, 0);
    next();
  }
});

export default router;
